import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

export default ({ data }) => {
  const content = data.markdownRemark

  return (
    <Layout>
      <article
        id={content.fields.slug.replace(/\//g, '')}
        className="content-page"
        dangerouslySetInnerHTML={{ __html: content.html }} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      html
    }
  }
`
